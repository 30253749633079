






















































































import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import SharedProposalHeader from "./Header.vue";
import SharedProposalListItem from "./ListItem.vue";

@Component({
    components: {
        BaseCard,
        SharedProposalHeader,
        SharedProposalListItem,
    },
})
export default class SharedProposalList extends Vue {
    @Prop() proposals!: any[];
    @Prop() search!: string;
    @Prop() loading!: boolean;
    @Prop() isLastPage!: boolean;
    @Prop() proposalsCount!: number;

    public isLoadingNextPage: boolean = false;

    public searchValue: string = "";

    get filteredProposals() {
        return this.proposals;
        // let filteredProposals = this.proposals.filter((proposal: any) => {
        //     const stringified = JSON.stringify(proposal);

        //     return stringified.includes(this.searchValue);
        // });

        // return filteredProposals;

        // return this.proposals;
    }

    @Emit("proposal:click")
    proposalOnClick(proposal: any) {
        return proposal;
    }

    @Emit("proposer:click")
    proposerOnClick(proposal: any) {
        return proposal;
    }

    @Emit("next:pagination")
    nextPagination() {
        return;
    }

    @Watch("isLoadingNextPage")
    loadNextPage() {
        if (this.isLastPage || this.loading) return;

        this.nextPagination();
        this.isLoadingNextPage = false;
    }
}
