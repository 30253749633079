



























import { Component, Vue, Watch } from "vue-property-decorator";
import SharedProposalList from "@/components/Shared/Proposal/List.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedProposalMostViewed from "@/components/Shared/Proposal/MostViewed.vue";
import ProposalService from "@/services/ProposalService";

@Component({
    components: {
        BaseWrapper,
        SharedProposalList,
        SharedProposalMostViewed,
    },
    metaInfo() {
        const me = this.$store.state.users.me;
        const page = "Proposal";
        const title = page;

        return {
            title: title,
        };
    },
})
export default class AppProposals extends Vue {
    public searchValue: string = "";
    public proposals: any[] = [];
    public isLoading: boolean = true;

    get me() {
        return this.$store.state.users.me;
    }

    get meRole() {
        return this.$store.getters["users/meRole"];
    }

    get ownedProposals() {
        let proposals: any[] = [...this.$store.state.proposals.proposals];
        const ownedProposals = proposals.filter((proposal: any) => {
            return proposal.user.id === this.me.id;
        });

        return ownedProposals;
    }

    get proposalsMostViewed() {
        let proposals: any[] = this.$store.state.proposals.proposals;
        const proposalsMostViewed = proposals.sort((a: any, b: any) => {
            const aCount: any = new Date(a.seens.length);
            const bCount: any = new Date(b.seens.length);

            return bCount - aCount;
        });

        return proposalsMostViewed.slice(0, 3);
    }

    get useGutter() {
        return this.$vuetify.breakpoint.mdAndUp;
    }

    proposalOnClick(proposal: any) {
        this.$router.push("/app/proposal/" + proposal.id);
    }

    proposerOnClick(proposal: any) {
        this.$router.push("/app/pengusul/" + proposal.id);
    }

    public proposalsTotal: number = 0;
    public currentTotal: number = 0;
    public currentPage: number = 1;
    public itemPerPage: number = 5;
    public isLastPage: boolean = false;

    async findAllProposal() {
        this.isLoading = true;

        const options: any = {
            // take: this.itemPerPage,
            page: this.currentPage,
            type: "APLIKASI",
        };
        const isProposer = this.meRole === "PROPOSER";
        const hasSearchValue = !!this.searchValue;
        if (isProposer) options.user = this.me.id;
        if (hasSearchValue) options.q = this.searchValue;

        const response = await ProposalService.findAll(options);
        const { proposals, total } = response.data;
        setTimeout(() => {
            this.proposals = [...this.proposals, ...proposals];
            this.$store.commit("proposals/SET_PROPOSALS", [...this.proposals]);
            this.proposalsTotal = total;
            this.currentTotal += proposals.length;

            if (this.currentTotal >= this.proposalsTotal)
                this.isLastPage = true;

            this.isLoading = false;
        }, 300);
    }

    async nextPagination() {
        this.currentPage++;
        this.findAllProposal();
    }

    resetData() {
        this.proposals = [];
        this.currentTotal = 0;
        this.currentPage = 1;
        this.isLastPage = false;
        this.$store.commit("proposals/SET_PROPOSALS", []);
    }

    @Watch("searchValue")
    searchProposal() {
        this.resetData();
        this.findAllProposal();
    }

    async mounted() {
        this.findAllProposal();
    }
}
