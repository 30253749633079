








































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseLink from "@/components/Base/BaseLink.vue";

@Component({
    components: {
        BaseCard,
        BaseLink,
    },
})
export default class SharedProposalMostViewed extends Vue {
    @Prop() proposals!: any[];
}
